<template>
  <div>
    <b-card>
      <b-row>
        <b-col cols="12">
          <menu-types :get-parent-menus="getParentMenus" />
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Başlık"
            label-for="title"
          >
            <b-form-input
              id="title"
              v-model="itemData.title"
              placeholder="Başlık"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Bağlantı"
            label-for="id_components"
          >
            <v-select
              id="id_components"
              v-model="id_components"
              placeholder="Bağlantı"
              :options="components"
              label="title"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <component
            :is="selectComponent"
            v-if="selectComponent"
          />
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Sıra No"
            label-for="ordering"
          >
            <b-form-input
              id="ordering"
              v-model="itemData.ordering"
              placeholder="Sıra No"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Bağlantı Tipi"
            label-for="target"
          >
            <v-select
              id="target"
              v-model="itemData.target"
              placeholder="Bağlantı"
              :options="targetTypes"
              label="title"
              :reduce="item => item.value"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Durum"
            label-for="status"
          >
            <v-select
              id="target"
              v-model="itemData.status"
              placeholder="Durum"
              :options="statues"
              label="title"
              :reduce="item => item.value"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
        >
          <b-form-group
            label="Üst Menü Öğesi"
            label-for="id_menus"
          >
            <v-select
              id="id_menus"
              v-model="itemData.id_menus"
              placeholder="Ana Menü"
              :options="parentMenus"
              label="title"
              :reduce="item => item.id"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
    <b-button
      variant="primary"
      @click="submitForm"
    >
      Kaydet
    </b-button>
  </div>
</template>
<script>
import {
  BButton, BCard, BCol, BFormGroup, BFormInput, BRow,
} from 'bootstrap-vue'
import MenuTypes from '@/views/Admin/Menus/elements/MenuTypes.vue'
import ServiceCategories from '@/views/Admin/Menus/components/ServiceCategories.vue'
import ServiceItem from '@/views/Admin/Menus/components/ServiceItem.vue'
import Segments from '@/views/Admin/Menus/components/Segments.vue'
import EstateCategories from '@/views/Admin/Menus/components/EstateCategories.vue'
import Contents from '@/views/Admin/Menus/components/Contents.vue'
import ExternalUrl from '@/views/Admin/Menus/components/ExternalUrl.vue'
import BlogCategories from '@/views/Admin/Menus/components/BlogCategories.vue'
import PressTypes from '@/views/Admin/Menus/components/PressTypes.vue'
import vSelect from 'vue-select'

export default {
  name: 'MenuForm',
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    MenuTypes,
    Contents,
    ExternalUrl,
    vSelect,
    BlogCategories,
    ServiceCategories,
    ServiceItem,
    Segments,
    EstateCategories,
    PressTypes,
  },
  props: {
    submitForm: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      id_components: null,
      selectComponent: null,
      targetTypes: [
        {
          title: 'Aynı Pencerede',
          value: '_self',
        },
        {
          title: 'Yeni Sekmede',
          value: '_blank',
        },
      ],
      statues: [
        {
          title: 'Aktif',
          value: '1',
        },
        {
          title: 'Pasif',
          value: '0',
        },
      ],
    }
  },
  computed: {
    idComponents() {
      return this.$store.getters['menus/id_components']
    },
    parentMenus() {
      return this.$store.getters['menus/dataList']
    },
    itemData() {
      return this.$store.getters['menus/dataItem']
    },
    components() {
      return this.$store.getters['components/dataList']
    },
  },
  watch: {
    id_components(val) {
      if (val) {
        this.itemData.id_components = val.id
        if (val.select_component) {
          this.selectComponent = val.select_component
        } else {
          this.selectComponent = null
          this.itemData.itemid = null
        }
      }
    },
    // Only Update Data!
    idComponents(val) {
      if (val.id) {
        this.id_components = val
      }
    },
  },
  created() {
    this.getParentMenus()
    this.getComponents()
  },
  methods: {
    getParentMenus() {
      const whereData = {
        'menus.id !=': this.itemData.id,
        'menus.id_menus': null,
      }
      if (this.itemData.id_menu_types) {
        whereData['menus.id_menu_types'] = this.itemData.id_menu_types
      }
      this.$store.dispatch('menus/getDataList', {
        select: [
          'menus.id AS id',
          'menus.title AS title',
        ],
        where: whereData,
      })
    },
    getComponents() {
      this.$store.dispatch('components/getDataList', {
        select: [
          'components.id AS id',
          'components.title AS title',
          'components.select_component AS select_component',
        ],
        where: {
          'components.is_menu': 1,
        },
      })
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
