<template>
  <b-form-group
    label="Menü Tipi"
    label-for="id_menu_types"
  >
    <v-select
      id="id_menu_types"
      v-model="itemData.id_menu_types"
      :options="dataList"
      label="title"
      :reduce="item => item.id"
      placeholder="Seçiniz"
      @input="getParentMenus"
    />
  </b-form-group>
</template>

<script>
import { BFormGroup } from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'MenuTypes',
  components: {
    BFormGroup,
    vSelect,
  },
  props: {
    getParentMenus: {
      type: Function,
      required: true,
    },
  },
  computed: {
    dataList() {
      return this.$store.getters['menuTypes/dataList']
    },
    itemData() {
      return this.$store.getters['menus/dataItem']
    },
  },
  created() {
    this.getDataList()
  },
  methods: {
    getDataList() {
      this.$store.dispatch('menuTypes/getDataList')
    },
  },
}
</script>
